<template>
  <v-container class="align-center">
    <v-row class="text-h3 font-weight-light secondary--text justify-center">
      {{ labels.environmentError }}
    </v-row>
    <v-row class="align-center justify-center">
      <v-img
        src="@/assets/broken.gif"
        class="my-2"
        contain
        max-width="50vw"
        max-height="50vh"
      />
    </v-row>
    <v-row class="text-h3 font-weight-light secondary--text justify-center">
      {{ labels.keepCalm }}
    </v-row>
  </v-container>
</template>

<script>
import { labels } from '@/assets/texts.json';

export default {
  name: 'environmentWarning',
  data: () => ({
    labels,
  }),
};
</script>

<style scoped>
</style>
